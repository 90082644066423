export default [
  {
    id: 1,
    content: "Get Started with Cloudenly",
    url:
      "https://resources.cloudenly.com/knowledge-base/humanar-onboarding-process/"
  },
  {
    id: 2,
    content: "Organisation",
    url: "https://resources.cloudenly.com/knowledge-base/organisation/"
  },
  {
    id: 3,
    content: "Organisation Structure",
    url: "https://resources.cloudenly.com/knowledge-base/functional-hierarchy/"
  },
  {
    id: 4,
    content: "Job Level",
    url: "https://resources.cloudenly.com/knowledge-base/job-level/"
  },
  {
    id: 5,
    content: "Designation",
    url: "https://resources.cloudenly.com/knowledge-base/designation/"
  },
  {
    id: 6,
    content: "People",
    url: "https://resources.cloudenly.com/knowledge-base/people/"
  },
  {
    id: 7,
    content: "Location and Tax",
    url: "https://resources.cloudenly.com/knowledge-base/location/"
  },
  {
    id: 10,
    content: "Employees",
    url: "https://resources.cloudenly.com/knowledge-base/humanar-employees/"
  },
  {
    id: 11,
    content: "Employee Directory",
    url:
      "https://resources.cloudenly.com/knowledge-base/humanar-employee-directory/"
  },
  {
    id: 12,
    content: "Groups",
    url: "https://resources.cloudenly.com/knowledge-base/employee-groups/"
  },
  {
    id: 14,
    content: "Payroll",
    url: "https://resources.cloudenly.com/knowledge-base/invoice-2/"
  },
  {
    id: 15,
    content: "Variable Earnings",
    url: "https://resources.cloudenly.com/knowledge-base/variable-earnings/"
  },
  {
    id: 16,
    content: "Employees",
    url: "https://resources.cloudenly.com/knowledge-base/humanar-employees/"
  },
  {
    id: 17,
    content: "Payrun",
    url: "https://resources.cloudenly.com/knowledge-base/payrun/"
  },
  {
    id: 19,
    content: "Payee",
    url: "https://resources.cloudenly.com/knowledge-base/payee/"
  },
  {
    id: 20,
    content: "Benefits",
    url: "https://resources.cloudenly.com/knowledge-base/benefits/"
  },
  {
    id: 21,
    content: "Leave",
    url: "https://resources.cloudenly.com/knowledge-base/leave/"
  },
  {
    id: 22,
    content: "Advance and Loans",
    url: "https://resources.cloudenly.com/knowledge-base/advance-and-loans/"
  },
  {
    id: 23,
    content: "ESS Overtime",
    url: "https://resources.cloudenly.com/knowledge-base/overtime-ess/"
  },
  {
    id: 24,
    content: "ESS Employee Self Service",
    url: "https://resources.cloudenly.com/categories/employee-self-service/"
  },
  {
    id: 25,
    content: "ESS Personal Info",
    url:
      "https://resources.cloudenly.com/knowledge-base/humanar-ess-personal-info/"
  },
  {
    id: 26,
    content: "ESS Benefits",
    url: "https://resources.cloudenly.com/knowledge-base/benefits/"
  },
  {
    id: 27,
    content: "Approvals",
    url: "https://resources.cloudenly.com/knowledge-base/approvals/"
  },
  {
    id: 28,
    content: "Settings",
    url: "https://resources.cloudenly.com/knowledge-base/settings/"
  },
  {
    id: 31,
    content: "Profile",
    url: "https://resources.cloudenly.com/knowledge-base/profile/"
  },
  {
    id: 32,
    content: "Pay Setting",
    url: "https://resources.cloudenly.com/knowledge-base/pay-setting/"
  },
  {
    id: 33,
    content: "Pay Schedule",
    url: "https://resources.cloudenly.com/knowledge-base/pay-schedule/"
  },
  {
    id: 34,
    content: "Pension Setting",
    url: "https://resources.cloudenly.com/knowledge-base/pension-setting/"
  },
  {
    id: 37,
    content: "Time Off",
    url9: "https://resources.cloudenly.com/knowledge-base/time-off/"
  }
];
