<template>
  <app-bar>
    <div class="w-full flex justify-between topBar">
      <div class="flex items-center">
        <!-- hamburger menu -->
        <div
          class="flex flex-col justify-between cursor-pointer"
          style="width: 30px; height: 30px"
          @click="$emit('open')"
        >
          <icon
            class-name="text-darkPurple"
            size="m"
            icon-name="toggle_side_bar"
          />
        </div>

        <!-- Logo -->
        <div class="text-xl font-bold flex ml-8 text-darkPurple">
          <span v-if="$OrgData.logoUrl" class="mr-3">
            <img
              style="height: 2rem; width: 2rem; border-radius: 50%"
              :src="$OrgData.logoUrl"
              alt="logo"
            />
          </span>
          <p style="margin-top: 0.125rem">
            {{ $OrgData.name }}
          </p>
          <span
            style="margin-top: 0.625rem"
            class="text-xs ml-1"
            v-if="appPage === 'ESS'"
            >{{ appPage }}</span
          >
        </div>
        <Menu bottom-right class="ml-10" v-if="appPage === 'Admin'">
          <template v-slot:title>
            <div class="bg-darkPurple rounded-md p-2">
              <Icon
                icon-name="icon-chevron-down"
                class="text-white"
                :class-name="'transformIcon'"
                size="xms"
              />
            </div>
          </template>
          <div style="width: 260px; min-height: 150px" class="py-3 px-2">
            <div
              style="padding: 0px 10px 15px; height: 30px"
              class="text-center"
            >
              <span
                class="text-xs text-romanSilver"
                v-if="orgList.length === 0"
              >
                Add or Sign In to an Account
              </span>
              <span class="text-xs text-romanSilver" v-else>
                Switch Account
              </span>
            </div>
            <template v-if="orgList.length > 0">
              <scroll-container height="300px">
              <div
                class="p-4 flex flex-grow hover:bg-ghostWhite cursor-pointer"
                v-for="(list, index) in orgList"
                :key="index"
                @click="handleSwitch(list)"
              >
                <div class="flex flex-col justify-center">
                  <Icon
                    icon-name="icon-switch"
                    :class="
                      $OrgData.id === list.id
                        ? `text-flame`
                        : `text-romanSilver`
                    "
                    size="xsm"
                  />
                </div>
                <span class="text-sm ml-5"> {{ list.name }}</span>
              </div>
              </scroll-container>
            </template>
            <div
              class="p-4 flex flex-grow hover:bg-ghostWhite cursor-pointer"
              @click="$router.push({ name: 'New Account Setup' })"
            >
              <div class="flex flex-col justify-center">
                <Icon icon-name="add-account" class="text-white" size="xsm" />
              </div>
              <span class="text-sm ml-5"> Add a new account </span>
            </div>
            <div
              class="p-4 flex flex-grow hover:bg-ghostWhite cursor-pointer"
              @click="$router.push({ name: 'Account Sync' })"
            >
              <div class="flex flex-col justify-center">
                <Icon icon-name="signIn" class="text-white" size="xsm" />
              </div>
              <span class="text-sm ml-5"> Sync to an existing account </span>
            </div>
          </div>
        </Menu>
      </div>
      <div v-if="appPage === 'Admin'" v-feature-request>
        <search-field bgcolor="#e9e9e9" placeholder="User guide" />
      </div>
      <div class="flex justify-between" style="width: 25%">
        <div
          class="flex w-full"
          :class="appPage === 'Admin' ? 'justify-around' : 'justify-around'"
        >
          <div
            class="font-extrabold text-flame cursor-pointer"
            style="display: flex; align-items: center"
            @click="feedbackForm"
          >
            Leave us a Feedback
          </div>
          <notification :app-page="appPage" />
          <div v-if="appPage === 'Admin'">
            <icon
              size="s"
              @click.native="$router.push({ name: 'Profile' }).catch(() => {})"
              class-name="h-full cursor-pointer mx-auto self-center "
              icon-name="settings"
            />
          </div>

          <Profile :app-page="appPage" />
        </div>
      </div>
    </div>
  </app-bar>
</template>

<script>
import AppBar from "@scelloo/cloudenly-ui/src/components/app-bar";
import SearchField from "@/components/user-search-field";
import Icon from "@/components/Icon";
import Menu from "@/components/Menu";
import ScrollContainer from '@/components/ScrollContainer';
import Profile from "./profile";
import Notification from "./notification";

export default {
  components: {
    Icon,
    Menu,
    AppBar,
    SearchField,
    Profile,
    Notification,
    ScrollContainer,
  },
  props: {
    appPage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      orgList: [],
    };
  },
  computed: {},
  methods: {
    feedbackForm() {
      const name = `${this.$AuthUser.fname} ${this.$AuthUser.lname}`;
      window.open(
        `https://docs.google.com/forms/d/e/1FAIpQLSfdKzyql2h6GzPp3GAjk-bhnkwigMw8qsQsXheN0MxtI1b98w/viewform?usp=pp_url&entry.1275434755=${this.$AuthUser.email}&entry.1057480809=${name}&entry.1166974658=${this.$AuthUser.phoneNo}`,
        "_blank"
      );
    },

    getAllUsersOrganisations() {
      this.$_getMultiOrganisation(this.$AuthUser.id).then((response) => {
        response.data.organisation.forEach((data) => {
          if (data) {
            if (data.organisation.id !== this.$OrgData.id) {
              this.orgList.push({
                id: data.organisation.id,
                name: data.organisation.name,
                userId: data.userId ? data.userId : this.$AuthUser.id
              });
            }
          }
        });
      });
    },

    handleSwitch(data) {
      const payload = {
        userId: data.userId,
        sync: data.userId === this.$AuthUser.id
      };

      this.$_switchOrganisation(data.id, payload).then((response) => {
        const jwtToken = response.data.token;
        const linchpin = response.data;

        window.localStorage.setItem("linchpin", JSON.stringify(linchpin));

        window.localStorage.setItem("token", jwtToken);

        window.location.assign("/admin/dashboard");
      });
    },
  },
  mounted() {
    this.orgList.push({
      id: this.$OrgData.id,
      name: this.$OrgData.name,
    });
    this.getAllUsersOrganisations();
  },
};
</script>

<style scoped>
/* .Icons:hover{
     background: #F7F7FF;
    box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06), 0px 1px 3px rgba(8, 7, 8, 0.1);
    border-radius: 5px;
    color: #F15A29;

 } */
.topBar {
  padding: 2px;
}
@media (min-width: 1024px) {
  .topBar {
    padding: 12px;
    height: 65px;
  }
}

.transformIcon {
  transform: rotate(90deg);
  cursor: pointer;
}
</style>
